// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Perfil {
    align-items: center;
    height: 100vh; 
    display: flex;
    justify-content: center;
}

.formul {
    display: grid;
    grid-template-columns: auto 1fr;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 2rem;
    max-width: 500px;
    width: 100%;
    background-color: #f9f9f9;
    gap: 1rem;
}

.Titulo {
    margin-bottom: 0.1vh;
    font-weight: bold;
    font-size: 1.5rem;
    grid-column: span 2;
    text-align: center;
}

.formul label {
    text-align: left;
    padding-right: 1rem;
}

.formul input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #007bff;
    border-radius: 4px;
    cursor: pointer;
    grid-column: span 2;
    color: white;
    border: none;
    margin-top: 1rem;
    padding: 0.7rem;
}
`, "",{"version":3,"sources":["webpack://./src/estilos/perfil.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,+BAA+B;IAC/B,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".Perfil {\r\n    align-items: center;\r\n    height: 100vh; \r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.formul {\r\n    display: grid;\r\n    grid-template-columns: auto 1fr;\r\n    border: 1px solid #ccc;\r\n    border-radius: 8px;\r\n    padding: 2rem;\r\n    max-width: 500px;\r\n    width: 100%;\r\n    background-color: #f9f9f9;\r\n    gap: 1rem;\r\n}\r\n\r\n.Titulo {\r\n    margin-bottom: 0.1vh;\r\n    font-weight: bold;\r\n    font-size: 1.5rem;\r\n    grid-column: span 2;\r\n    text-align: center;\r\n}\r\n\r\n.formul label {\r\n    text-align: left;\r\n    padding-right: 1rem;\r\n}\r\n\r\n.formul input {\r\n    width: 100%;\r\n    padding: 0.5rem;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n}\r\n\r\nbutton {\r\n    background-color: #007bff;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    grid-column: span 2;\r\n    color: white;\r\n    border: none;\r\n    margin-top: 1rem;\r\n    padding: 0.7rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
