








































































































































































































































































































































































































































































































































































































































































































































































































































































































export const KEY= "as34423asj3/$kad";

export const nums= [73902,5,2,9064731,20,3,987654321]

export const encriptadar= (numero) =>{
    let cifrado= numero*3
    cifrado/=2
    cifrado+=10000
    cifrado*=4
    return cifrado;
}

export const desencriptar= (numero) =>{
    let original= numero/4
    original-=10000
    original*=2
    original/=3
    console.log(original)
    return original;
}