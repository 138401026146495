// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* estilos/buscador.css */

.contenedor {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    z-index: 1000; /* Para que esté encima de otros elementos */
}

.buscador {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.buscador input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.buscador table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.buscador th, .buscador td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.buscador th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.buscador tr:hover {
    background-color: #f1f1f1;
}

.buscador p {
    color: #777;
    font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/estilos/buscador.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC,EAAE,mCAAmC;IACzE,aAAa,EAAE,4CAA4C;AAC/D;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["/* estilos/buscador.css */\r\n\r\n.contenedor {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */\r\n    z-index: 1000; /* Para que esté encima de otros elementos */\r\n}\r\n\r\n.buscador {\r\n    background-color: #fff;\r\n    border-radius: 8px;\r\n    padding: 20px;\r\n    width: 80%;\r\n    max-width: 600px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\r\n    text-align: center;\r\n}\r\n\r\n.buscador input[type=\"text\"] {\r\n    width: 100%;\r\n    padding: 10px;\r\n    margin-bottom: 15px;\r\n    border-radius: 4px;\r\n    border: 1px solid #ccc;\r\n    font-size: 16px;\r\n}\r\n\r\n.buscador table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    margin-top: 10px;\r\n}\r\n\r\n.buscador th, .buscador td {\r\n    padding: 12px;\r\n    text-align: center;\r\n    border-bottom: 1px solid #ddd;\r\n}\r\n\r\n.buscador th {\r\n    background-color: #f5f5f5;\r\n    font-weight: bold;\r\n}\r\n\r\n.buscador tr:hover {\r\n    background-color: #f1f1f1;\r\n}\r\n\r\n.buscador p {\r\n    color: #777;\r\n    font-style: italic;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
